require(['angular', 'jquery', './angular/app'], function (
    angular,
    $
) {
    'use strict';

    if (typeof Raven != 'undefined' && Raven) {
        Raven.addPlugin(Raven.Plugins.Angular, angular);
    }

    $(document).ready(function () {
        // text-angular-sanitize requires this function that is not a part of angular anymore
        angular.lowercase = (string) => {
            if (string) {
                return string.toLowerCase();
            }
            return string;
        };

        angular.bootstrap(document, ['app']);
    });
});
